<template>
  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>Nick Manoukidis</span></strong>
      </div>
      <div class="credits">
      </div>
    </div>
  </footer><!-- End  Footer -->
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>