<template>
  <router-view/>
</template>

<script>

import {useI18n} from "vue-i18n";

export default {
  name: 'App',
  setup () {
    const {t, locale} = useI18n();

    return {t, locale};
  },
  data() {
    return {
      locales: [
        {
          "title": "Ελληνικά",
          "short": "el",
          "flag": require("@/assets/img/flags/gr.png")
        },
        {
          "title": "English",
          "short": "en",
          "flag": require("@/assets/img/flags/usa.png")
        }
      ]
    }
  }
}

</script>

