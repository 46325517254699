<template>
  <!-- ======= About Section ======= -->
  <section id="about" class="about">
    <div class="container">

      <div class="section-title">
        <h2>{{ $t('about.title') }}</h2>
        <p>{{ $t('about.subtitle') }}</p>
      </div>

      <div class="row">
        <div class="col-lg-4" data-aos="fade-right">
          <img :src="require('@/assets/img/profile-img.jpg')" class="img-fluid" alt="profile-pic">
        </div>
        <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
          <h3>{{ $t('about.card.title') }}</h3>
          <div class="row">
            <div class="col-lg-6">
              <ul>
                <li><i class="bi bi-chevron-right"></i> <strong>{{ $t('about.card.birthday.0') }}:</strong> <span>{{ $t('about.card.birthday.1') }}</span></li>
                <li><i class="bi bi-chevron-right"></i> <strong>{{ $t('about.card.city.0') }}:</strong> <span>{{ $t('about.card.city.1') }}</span></li>
                <li><i class="bi bi-chevron-right"></i> <strong>{{ $t('about.card.age') }}:</strong> <span>{{ calculateAge }}</span></li>
              </ul>
            </div>
            <div class="col-lg-6">
              <ul>
                <li><i class="bi bi-chevron-right"></i> <strong>{{ $t('about.card.email') }}:</strong> <span><a href="javascript:void(0);" @click="scrollToContact">info@nmanoukidis.eu</a></span></li>
                <li><i class="bi bi-chevron-right"></i> <strong>{{ $t('about.card.phone.0') }}:</strong> <span><a href="tel:+306995752061">{{ $t('about.card.phone.1') }}</a></span></li>
                <li><i class="bi bi-chevron-right"></i> <strong>{{ $t('about.card.degree.0') }}:</strong> <span>{{ $t('about.card.degree.1') }}</span></li>
              </ul>
            </div>
          </div>
          <p style="text-align: justify;">{{ $t('about.card.intro') }}</p>
        </div>
      </div>

    </div>
  </section><!-- End About Section -->
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      birthdate: new Date('1998-04-06')
    }
  },
  computed: {
    calculateAge() {
      const today = new Date();
      const birthdate = this.birthdate;

      let age = today.getFullYear() - birthdate.getFullYear();

      if (today.getMonth() < birthdate.getMonth() || today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate()) {
        age--;
      }
      return age;
    }
  },
  methods: {
    scrollToContact() {
      document.getElementById('contact-link').click();
    }
  }
}
</script>

<style scoped>

</style>