<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex flex-column justify-content-center align-items-center">
    <div class="hero-container" data-aos="fade-in">
      <h1>{{ $t('hero.name') }} {{ $t('hero.surname') }}</h1>
      <p>{{ $t('hero.description') }}
        <span class="typed" :data-typed-items="$t('hero.description_types')"></span>
      </p>
    </div>
  </section><!-- End Hero -->
</template>

<script>

export default {
  name: "Hero",
}

</script>
