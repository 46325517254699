<template>
  <!-- ======= Resume Section ======= -->
  <section id="resume" class="resume">
    <div class="container">

      <div class="section-title">
        <h2>{{ $t('resume.title') }}</h2>
      </div>

      <div class="row">
        <div class="col-lg-6" data-aos="fade-up">
          <h3 class="resume-title">{{ $t('resume.education.title') }}</h3>
          <div class="resume-item pb-0">
            <h4>{{ $t('resume.education.items.bachelor.0') }}</h4>
            <ul>
              <li>{{ $t('resume.education.items.bachelor.1') }}</li>
              <li>{{ $t('resume.education.items.bachelor.2') }}</li>
              <li>{{ $t('resume.education.items.bachelor.3') }}</li>
            </ul>
          </div>

          <div class="resume-item pb-0">
            <h4>{{ $t('resume.education.items.high-school.0') }}</h4>
            <ul>
              <li>{{ $t('resume.education.items.high-school.1') }}</li>
              <li>{{ $t('resume.education.items.high-school.2') }}</li>
              <li>{{ $t('resume.education.items.high-school.3') }}</li>
            </ul>
          </div>

          <h3 class="resume-title">{{ $t('resume.languages.title') }}</h3>
          <div class="resume-item">
            <h4>{{ $t('resume.languages.items.english.0') }}</h4>
            <ul>
              <li>{{ $t('resume.languages.items.english.1') }}</li>
              <li>{{ $t('resume.languages.items.english.2') }}</li>
            </ul>
          </div>
          <div class="resume-item">
            <h4>{{ $t('resume.languages.items.german.0') }}</h4>
            <ul>
              <li>{{ $t('resume.languages.items.german.1') }}</li>
              <li>{{ $t('resume.languages.items.german.2') }}</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
          <h3 class="resume-title">{{ $t('resume.professional.title') }}</h3>

          <!-- PROCON -->
          <div class="resume-item">
            <h4>{{ $t('resume.professional.items.procon.0') }}</h4>
            <h5>{{ $t('resume.professional.items.procon.1') }}</h5>
            <p><em>{{ $t('resume.professional.items.procon.2') }} </em></p>
            <ul>
              <li>{{ $t('resume.professional.items.procon.3') }}</li>
            </ul>
          </div>

          <!-- CustomLab -->
          <div class="resume-item">
            <h4>{{ $t('resume.professional.items.custom-lab.0') }}</h4>
            <h5>{{ $t('resume.professional.items.custom-lab.1') }}</h5>
            <p><em>{{ $t('resume.professional.items.custom-lab.2') }} </em></p>
            <ul>
              <li>{{ $t('resume.professional.items.custom-lab.3') }}</li>
              <li>{{ $t('resume.professional.items.custom-lab.4') }}</li>
              <li>{{ $t('resume.professional.items.custom-lab.5') }}</li>
              <li>{{ $t('resume.professional.items.custom-lab.6') }}</li>
            </ul>
          </div>

          <!-- GR-NET -->
          <div class="resume-item">
            <h4>{{ $t('resume.professional.items.gr-net.0') }}</h4>
            <h5>{{ $t('resume.professional.items.gr-net.1') }}</h5>
            <p><em>{{ $t('resume.professional.items.gr-net.2') }} </em></p>
            <ul>
              <li>{{ $t('resume.professional.items.gr-net.3') }}</li>
              <li>{{ $t('resume.professional.items.gr-net.4') }}</li>
              <li>{{ $t('resume.professional.items.gr-net.5') }}</li>
            </ul>
          </div>

        </div>
      </div>

    </div>
  </section><!-- End Resume Section -->
</template>

<script>
export default {
  name: "Resume"
}
</script>

<style scoped>

</style>