export default {
  "hero": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχική"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νικόλαος"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μανουκίδης"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είμαι"])},
    "description_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Developer, Web Designer"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ονομάζομαι Νικόλαος Μανουκίδης. Πρωταρχικός μου στόχος είναι η εξέλιξη των δεξιοτήτων που αφορούν την ανάπτυξη ιστοσελίδων και διαδικτυακών εφαρμογών."])},
    "card": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γενικές Πληροφορίες"])},
      "birthday": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημ/νία Γέννησης"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 Απριλίου 1998"])}
      ],
      "city": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πόλη"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θεσσαλονίκη"])}
      ],
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ηλικία"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ηλ. Ταχυδρομείο"])},
      "phone": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τηλέφωνο"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["699 575 2061"])}
      ],
      "degree": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πτυχίο"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor"])}
      ],
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γεννήθηκα στη Θεσσαλονίκη το 1998. Η ενασχόλησή μου με τον τομέα της Πληροφορικής ξεκίνησε από αρκετά νεαρή ηλικία κυρίως λόγω του επαγγέλματος του πατέρα μου, ο οποίος είναι Προγραμματιστής. Αποφοίτησα από το 1ο ΕΠΑΛ Καλαμαριάς, όπου ακολούθησα την ειδικότητα της Πληροφορικής. Το 2016 απέκτησα πλήρη υποτροφία για το CITY COLLEGE (Διεθνές Τμήμα του Πανεπιστημίου του Sheffield), όπου, κατά τη διάρκεια της φοίτησής μου, αναφέρθηκα για δύο συνεχόμενες χρονιές ως \"Φοιτητής του Εξαμήνου\". Το 2020 αποφοίτησα με επιτυχία, ως κάτοχος πτυχίου Bachelor στην Πληροφορική και από το Σεπτέμβριο του 2022 συνεργάζομαι με την εταιρεία PROCON (Professional Consulting) ως προγραμματιστής."])}
    }
  },
  "skills": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεξιότητες"])}
  },
  "resume": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βιογραφικό"])},
    "education": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπαίδευση"])},
      "items": {
        "bachelor": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor στην Πληροφορική"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2016 - 2020"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CITY COLLEGE - Το διεθνές τμήμα του Πανεπιστημίου του Sheffield"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τετραετής Υποτροφία - Βαθμός αποφοίτησης: Class II - Division II"])}
        ],
        "high-school": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απολυτήριο Λυκείου"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2016"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1ο ΕΠΑΛ Καλαμαριάς, Θεσσαλονίκη"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδικότητα πληροφορικής - Βαθμός αποφοίτησης: 16.5/20"])}
        ]
      }
    },
    "languages": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ξένες Γλώσσες"])},
      "items": {
        "english": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πτυχίο Αγγλικής Γλώσσας"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεκέμβριος, 2017"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proficiency, C2"])}
        ],
        "german": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πτυχίο Γερμανικής Γλώσσας"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νοέμβριος, 2015"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goethe Zertifikat, B2"])}
        ]
      }
    },
    "professional": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαγγελλματική Εμπειρία"])},
      "items": {
        "procon": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Developer"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09/2022 - Σήμερα"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROCON (Professional Consulting)"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέλος της ομάδας των Web Developers"])}
        ],
        "custom-lab": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP Developer"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03/2021 - 08/2021"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customlab Development & Web services"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέλος της ομάδας των Web Developers"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατασκευή Επαγγελματικών ιστοσελίδων"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τηλεφωνική Εξυπηρέτηση"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχείριση Support Email"])}
        ],
        "gr-net": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Developer"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09/2020 - 03/2021"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GR-NET Ε.Ε."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέλος της ομάδας των Web Developers"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατασκευή Επαγγελματικών ιστοσελίδων & Ηλεκτρονικών Καταστημάτων."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απομακρυσμένη Τεχνική Υποστήριξη"])}
        ]
      }
    }
  },
  "portfolio": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείγματα Δουλειάς"])},
    "categories": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολα"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laravel"])}
    ],
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε Λεπτομέρειες"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιγραφή"])},
    "Octopus": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octopus"])},
      "overview": {
        "category": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Εφαρμογή"])}
        ],
        "technologies": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τεχνολογίες"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laravel, PHP 7.4, HTML 5, Bootstrap 4, CSS 3, JavaScript, Ajax, MySQL"])}
        ],
        "date": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεκέμβριος, 2020"])}
        ],
        "collaborators": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεργάτες"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αθανασάκης Θεοδόσης"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://theathral.github.io"])}
        ],
        "url": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεσμος"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octopus"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://octopus.nmanoukidis.eu/"])}
        ]
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εφαρμογή \"Octopus\" είναι ένα σύστημα διαχείρισης συμβάντων εντός μίας μικρής επιχείρησης. Περιέχει ομάδες όπου μπορεί να ενταχθεί ένας χρήστης και να αποκτήσει πρόσβαση σε δυνατότητες της εφαρμογής. Οι χρήστες, αν το επιτρέπουν τα δικαιώματά τους, δύνανται να πραγματοποιήσουν τις παρακάτω ενέργειες: Διαχείριση Συμβάντων και Αιτημάτων (Υποβολή, Παρακολούθηση Καταστάσεων, Ολοκλήρωση), Παρακολούθηση Στατιστικών Εφαρμογής, Συνομιλία μεταξύ Χρηστών, Δημιουργία Ρόλων και Δικαιωμάτων, Παρακολούθηση Αρχείου Καταγραφών. Η εφαρμογή προσφέρεται ως SaaS, ενώ έχει υλοποιηθεί σε γλώσσα προγραμματισμού \"Laravel\" για μεγαλύτερη ασφάλεια."])}
    },
    "Mentors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer Mentoring System"])},
      "overview": {
        "category": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Εφαρμογή"])}
        ],
        "technologies": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τεχνολογίες"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP 7.3, HTML 5, Bootstrap 4, CSS 3, JavaScript, Ajax, MySQL"])}
        ],
        "date": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιούνιος, 2020"])}
        ]
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το \"Peer Mentoring System\" είναι μία διαδικτυακή εφαρμογή μέσω της οποίας δίνεται η δυνατότητα σε φοιτητές να προσφέρουν και να δεχτούν βοήθεια από συναδέλφους τους με τη βοήθεια των ήδη υπάρχοντων μέσων τεχνολογίας. Με αυτόν τον τρόπο, οι φοιτητές αποφασίζουν είτε να μοιραστούν τη γνώση τους με τους πιο αδύναμους συνφοιτητές τους, είτε να λάβουν οι ίδιοι οποιουδήποτε είδους βοήθεια από τους πιο \"δυνατούς\" στα πλαίσια της ακαδημαϊκής τους καριέρας. Το σύστημα αυτό προσφέρει αυτού του είδους τη δυνατότητα συνεργασίας μεταξύ των φοιτητών ακόμα και αν οι δύο πιθανοί συνεργάτες δεν γνωρίζονται από κοντά."])}
    },
    "Frontizo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontizo"])},
      "overview": {
        "category": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Εφαρμογή"])}
        ],
        "technologies": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τεχνολογίες"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP 7.1, HTML 5, Bootstrap 4, CSS 3, JavaScript, Ajax, MySQL"])}
        ],
        "date": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νοέμβριος, 2019"])}
        ]
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το όνομα του πρότζεκτ προέρχεται από την ελληνική λέξη \"φροντίζω\". Η αρχική ιδέα προέκυψε από μία φιλοζωική οργάνωση του Δήμου Θεσσαλονίκης και υλοποιήθηκε ως ομαδική εργασία στα πλαίσια πρακτικής στο πανεπιστήμιο. Τα μέλη της οργάνωσης έχουν την δυνατότητα να καταχωρούν πληροφορίες για τα ζώα που μαζεύουν, εμβολιάζουν, τοποθετούν αναγνωριστικό chip κτλ. Μπορούν επίσης να επιβλέπουν την αποθήκη τους, τις δωρεές που δέχονται, καθώς και τις πληρωμές που πραγματοποιούν με τους προμηθευτές τους."])}
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικοινωνία"])},
    "location": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τοποθεσία"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καλαμαριά, Θεσσαλονίκη"])}
    ],
    "call": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τηλέφωνο"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["699 575 2061"])}
    ],
    "resume": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το Βιογραφικό μου"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manoukidis-cv-gr.pdf"])}
    ],
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το όνομά σας"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το email σας"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θέμα"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κείμενο"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή Μηνύματος"])},
      "sending": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το μήνυμά σας στάλθηκε. Ευχαριστώ για την επικοινωνία."])}
      ]
    }
  },
  "errors": {
    "404": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ΣΕΛΙΔΑ ΔΕΝ ΒΡΕΘΗΚΕ"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΑΡΧΙΚΗ"])}
    ]
  },
  "responses": {
    "errors": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ εισάγετε ένα έγκυρο όνομα"])},
      "recaptcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα επιβεβαίωσης recaptcha"])}
    }
  }
}