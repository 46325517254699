<template>

    <Sidebar/>

    <div id="main-content">

      <main id="main">

      <Breadcrumb :title="$t('portfolio.'+project+'.title')"/>

      <!-- ======= Portfolio Details Section ======= -->
      <section id="portfolio-details" class="portfolio-details">
        <div class="container">

          <div class="row gy-4">

            <div class="col-lg-8">
              <Images :project="project"/>
            </div>

            <div class="col-lg-4">
              <div class="portfolio-info">
                <h3>{{ $t('portfolio.information') }}</h3>
                <ul>
                  <li v-if="$t('portfolio.'+project+'.overview.category.0') !== 'portfolio.'+project+'.overview.category.0'"><strong>{{ $t('portfolio.'+project+'.overview.category.0') }}</strong>: {{ $t('portfolio.'+project+'.overview.category.1') }}</li>
                  <li v-if="$t('portfolio.'+project+'.overview.technologies.0') !== 'portfolio.'+project+'.overview.technologies.0'"><strong>{{ $t('portfolio.'+project+'.overview.technologies.0') }}</strong>: {{ $t('portfolio.'+project+'.overview.technologies.1') }}</li>
                  <li v-if="$t('portfolio.'+project+'.overview.date.0') !== 'portfolio.'+project+'.overview.date.0'"><strong>{{ $t('portfolio.'+project+'.overview.date.0') }}</strong>: {{ $t('portfolio.'+project+'.overview.date.1') }}</li>
                  <li v-if="$t('portfolio.'+project+'.overview.collaborators.0') !== 'portfolio.'+project+'.overview.collaborators.0'"><strong>{{ $t('portfolio.'+project+'.overview.collaborators.0') }}</strong>: <a :href="$t('portfolio.'+project+'.overview.collaborators.2')" :target="'_blank'">{{ $t('portfolio.'+project+'.overview.collaborators.1') }}</a></li>
                  <li v-if="$t('portfolio.'+project+'.overview.url.0') !== 'portfolio.'+project+'.overview.url.0'"><strong>{{ $t('portfolio.'+project+'.overview.url.0') }}</strong>: <a :href="$t('portfolio.'+project+'.overview.url.2')" :target="'_blank'">{{ $t('portfolio.'+project+'.overview.url.1') }}</a></li>
                </ul>
              </div>
              <div class="portfolio-description" style="text-align: justify">
                <h2>{{ $t('portfolio.description') }}</h2>
                <p>{{ $t('portfolio.'+project+'.description') }}</p>
              </div>
            </div>

          </div>

        </div>
      </section><!-- End Portfolio Details Section -->
    </main>

    <Footer/>

  </div>


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>


</template>

<script>

import '@/assets/css/google-fonts.css'

import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'boxicons/css/boxicons.min.css'
import 'glightbox/dist/css/glightbox.min.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper-bundle.css'

import '@/assets/css/style.css'


import Swiper from "swiper"

import Footer from "@/components/includes/Footer";
import Sidebar from "@/components/includes/Sidebar";
import Breadcrumb from "@/components/portfolio-details/Breadcrumb";
import Images from "@/components/portfolio-details/Images";

export default {
  name: "PortfolioDetails",
  mounted() {
    /**
     * Portfolio details slider
     */
    new Swiper('.portfolio-details-slider', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });

    document.getElementById('main-content').addEventListener('click', function() {
      if (window.innerWidth < 768) {
        // Collapse the sidebar using Bootstrap's collapse method
        let body = document.querySelector('.mobile-nav-active');
        if (body) {
          body.classList.remove('mobile-nav-active');
        }
        let sidebar = document.querySelector('.mobile-nav-toggle');
        if (sidebar) {
          sidebar.classList.remove('bi-x');
          sidebar.classList.add('bi-list');
        }
      }
    });

  },
  components: {
    Sidebar, Images, Breadcrumb, Footer
  },
  props: ['project']
}
</script>