<template>
  <!-- ======= Skills Section ======= -->
  <section id="skills" class="skills section-bg">
    <div class="container">

      <div class="section-title">
        <h2>{{ $t('skills.title') }}</h2>
      </div>

      <div class="row skills-content">

        <div class="col-lg-6" data-aos="fade-up">

          <div class="progress">
            <span class="skill">{{ items.html[0] }} <i class="val">{{ items.html[1] }}%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" :aria-valuenow="items.html[1]" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">{{ items.js[0] }} <i class="val">{{ items.js[1] }}%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" :aria-valuenow="items.js[1]" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">{{ items.mysql[0] }} <i class="val">{{ items.mysql[1] }}%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" :aria-valuenow="items.mysql[1]" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">{{ items.java[0] }} <i class="val">{{ items.java[1] }}%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" :aria-valuenow="items.java[1]" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

        </div>

        <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">

          <div class="progress">
            <span class="skill">{{ items.php[0] }} <i class="val">{{ items.php[1] }}%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" :aria-valuenow="items.php[1]" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">{{ items.laravel[0] }} <i class="val">{{ items.laravel[1] }}%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" :aria-valuenow="items.laravel[1]" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">{{ items.vue[0] }} <i class="val">{{ items.vue[1] }}%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" :aria-valuenow="items.vue[1]" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">{{ items.latex[0] }} <i class="val">{{ items.latex[1] }}%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" :aria-valuenow="items.latex[1]" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

        </div>

      </div>

    </div>
  </section><!-- End Skills Section -->
</template>

<script>
export default {
  name: "Skills",
  data() {
    return {
      "items": {
        "html": ["HTML / CSS", 90],
        "js": ["JavaScript / JQuery / AJAX", 80],
        "mysql": ["MySQL", 90],
        "java": ["JAVA", 40],
        "php": ["PHP", 90],
        "laravel": ["LARAVEL", 70],
        "vue": ["VUE JS", 25],
        "latex": ["LaTeX", 65]
      }
    }
  },
}
</script>

<style scoped>

</style>