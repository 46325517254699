<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container">

      <div class="section-title">
        <h2>{{ $t('contact.title') }}</h2>
      </div>

      <div class="row" data-aos="fade-in">

        <div class="col-lg-5 d-flex align-items-stretch">
          <div class="info">
            <div class="address">
              <i class="bi bi-geo-alt"></i>
              <h4>{{ $t('contact.location.0') }}:</h4>
              <p>{{ $t('contact.location.1') }}</p>
            </div>

            <div class="email">
              <a href="mailto:info@nmanoukidis.eu"><i class="bi bi-envelope"></i></a>
              <h4>Email:</h4>
              <p>info@nmanoukidis.eu</p>
            </div>

            <div class="phone">
              <a href="tel:+306995752061"><i class="bi bi-phone"></i></a>
              <h4>{{ $t('contact.call.0') }}:</h4>
              <p>{{ $t('contact.call.1') }}</p>
            </div>

            <a class="download"
               :href="(this.$i18n.locale === 'el' ? 'documents/Manoukidis-cv-gr.pdf' : 'documents/Manoukidis-cv-en.pdf')"
               :download="(this.$i18n.locale === 'el' ? 'Manoukidis-cv-gr.pdf' : 'Manoukidis-cv-en.pdf')"
               target="_blank">
              <a><i class="bi bi-download"></i></a>
              <h4>{{ $t('contact.resume.0') }}:</h4>
              <p>{{ $t('contact.resume.1') }}</p>
            </a>

          </div>

        </div>

        <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
          <form @submit.prevent="validateAndSubmit" role="form" class="php-email-form" data-recaptcha-site-key="6Ld-KYAbAAAAAAWK0P1AmwZ2kHPtAdMIvPqNE-4K">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="name">{{ $t('contact.form.name') }}</label>
                <input type="text" name="name" class="form-control" id="name" v-model="inputs.name" required>
              </div>
              <div class="form-group col-md-6">
                <label for="name">{{ $t('contact.form.email') }}</label>
                <input type="email" class="form-control" name="email" id="email" v-model="inputs.email" required>
              </div>
            </div>
            <div class="form-group">
              <label for="name">{{ $t('contact.form.subject') }}</label>
              <input type="text" class="form-control" name="subject" id="subject" v-model="inputs.subject" required>
            </div>
            <div class="form-group">
              <label for="name">{{ $t('contact.form.text') }}</label>
              <textarea class="form-control" name="message" rows="10" v-model="inputs.message" required></textarea>
            </div>
            <div class="my-3">
              <div class="loading">{{ $t('contact.form.sending.0') }}</div>
              <div class="error-message"></div>
              <div class="sent-message">{{ $t('contact.form.sending.1') }}</div>
            </div>
            <div class="text-center"><button type="submit">{{ $t('contact.form.button') }}</button></div>
          </form>
        </div>

      </div>

    </div>
  </section><!-- End Contact Section -->
</template>

<script>

import axios from "axios";

export default {
  name: "Contact",
  data() {
    return {
      form: document.querySelector('.php-email-form'),
      valid: true,
      inputs: { "name": "", "email": "", "subject": "", "message": "" },
    };
  },
  methods: {
    validateAndSubmit() {

      document.querySelector('.loading').classList.add('d-block');
      document.querySelector('.error-message').classList.remove('d-block');
      document.querySelector('.sent-message').classList.remove('d-block');

      this.$recaptcha("contactus").then((token) => {
        this.inputs.token = token; // Append token to form data
        this.submitForm(this.inputs); // Submit the form
      });

    },
    submitForm(data) {
      axios.post('contact.php', data, {
        headers: {
          "content-type": "application/json",
          "Accept": "application/json"
        }
      })
        .then((res) => {
          // Success Action
          if (res['data']['success'] === true) {
            document.querySelector('.loading').classList.remove('d-block');
            document.querySelector('.sent-message').classList.add('d-block');
            const form = document.querySelector('.php-email-form');
            form.querySelectorAll('input, textarea').forEach((element) => {
              element.value = '';
            })
          } else {
            this.displayError(this.$t('responses.errors.'+res['data']['error']));
          }
        })
        .catch((error) => {
          // error.response.status Check status code
          this.displayError(error);
        }).finally(() => {
          //Perform action in always
          this.clearMessages();
        });
    },
    displayError(error) {
      document.querySelector('.loading').classList.remove('d-block');
      document.querySelector('.error-message').innerHTML = error;
      document.querySelector('.error-message').classList.add('d-block');
    },
    clearMessages() {
      setTimeout(
      function() {
        document.querySelector('.loading').classList.remove('d-block');
        document.querySelector('.error-message').classList.remove('d-block');
        document.querySelector('.error-message').innerHTML = '';
      }, 5000);
    }
  }
}
</script>

<style scoped>

</style>