<template>
  <div class="portfolio-details-slider swiper-container">
    <div class="swiper-wrapper align-items-center">

      <div class="swiper-slide" v-for="item in images[project]" :key="item.id">
        <img :src="item.image" :alt="'image ' + item.id">
      </div>

    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>

import 'swiper/swiper-bundle.min.css'

import Swiper from "swiper/swiper-bundle.min.js"

export default {
  name: "Images",
  props: ['project'],
  data() {
    return {
      images: {
        "Octopus": [
          {"id": 1, "image": require('@/assets/img/portfolio/octopus/'+this.$i18n.locale+'/octopus-1.png')},
          {"id": 2, "image": require('@/assets/img/portfolio/octopus/'+this.$i18n.locale+'/octopus-2.png')},
          {"id": 3, "image": require('@/assets/img/portfolio/octopus/'+this.$i18n.locale+'/octopus-3.png')},
          {"id": 4, "image": require('@/assets/img/portfolio/octopus/'+this.$i18n.locale+'/octopus-4.png')},
          {"id": 5, "image": require('@/assets/img/portfolio/octopus/'+this.$i18n.locale+'/octopus-5.png')},
          {"id": 6, "image": require('@/assets/img/portfolio/octopus/'+this.$i18n.locale+'/octopus-6.png')},
          {"id": 7, "image": require('@/assets/img/portfolio/octopus/'+this.$i18n.locale+'/octopus-7.png')},
          {"id": 8, "image": require('@/assets/img/portfolio/octopus/'+this.$i18n.locale+'/octopus-8.png')},
          {"id": 9, "image": require('@/assets/img/portfolio/octopus/'+this.$i18n.locale+'/octopus-9.png')},
          {"id": 10, "image": require('@/assets/img/portfolio/octopus/'+this.$i18n.locale+'/octopus-10.png')},
        ],
        "Mentors": [
          {"id": 1, "image": require('@/assets/img/portfolio/mentors/mentors-1.png')},
          {"id": 2, "image": require('@/assets/img/portfolio/mentors/mentors-2.png')},
          {"id": 3, "image": require('@/assets/img/portfolio/mentors/mentors-3.png')},
          {"id": 4, "image": require('@/assets/img/portfolio/mentors/mentors-4.png')},
          {"id": 5, "image": require('@/assets/img/portfolio/mentors/mentors-5.png')},
          {"id": 6, "image": require('@/assets/img/portfolio/mentors/mentors-6.png')},
          {"id": 7, "image": require('@/assets/img/portfolio/mentors/mentors-7.png')},
          {"id": 8, "image": require('@/assets/img/portfolio/mentors/mentors-8.png')},
          {"id": 9, "image": require('@/assets/img/portfolio/mentors/mentors-9.png')}
        ],
        "Frontizo": [
          {"id": 1, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-1.png')},
          {"id": 2, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-2.png')},
          {"id": 3, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-3.png')},
          {"id": 4, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-4.png')},
          {"id": 5, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-5.png')},
          {"id": 6, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-6.png')},
          {"id": 7, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-7.png')},
          {"id": 8, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-8.png')},
          {"id": 9, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-9.png')},
          {"id": 10, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-10.png')},
          {"id": 11, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-11.png')},
          {"id": 12, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-12.png')},
          {"id": 13, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-13.png')},
          {"id": 14, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-14.png')},
          {"id": 15, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-15.png')},
          {"id": 16, "image": require('@/assets/img/portfolio/animal-welfare/animal-welfare-16.png')}
        ],

      }
    }
  },
  mounted() {
    /**
     * Portfolio details slider
     */
    new Swiper('.portfolio-details-slider', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });
  }
}
</script>

<!--<style>-->
<!--  @import "swiper/";-->
<!--</style>-->
