import { createApp } from 'vue'
import App from '@/App.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import i18n from './i18n'
import router from './router'

const app = createApp(App)
    .use(router)
    .use(i18n)
    .use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY });
app.mount('#app')
