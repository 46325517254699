import {createRouter, createWebHistory} from 'vue-router'
import Home from "@/views/Home";
import PortfolioDetails from "@/views/PortfolioDetails";
import NotFound from "@/views/NotFound";


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/portfolio/octopus',
    name: 'Octopus',
    component: PortfolioDetails,
    props: {"project": "Octopus"}
  },
  {
    path: '/portfolio/mentors',
    name: 'Mentors',
    component: PortfolioDetails,
    props: {"project": "Mentors"}
  },
  {
    path: '/portfolio/frontizo',
    name: 'Frontizo',
    component: PortfolioDetails,
    props: {"project": "Frontizo"}
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
