<template>
  <!-- ======= Mobile nav toggle button ======= -->
  <i class="bi bi-list mobile-nav-toggle d-xl-none"></i>

  <!-- ======= Header ======= -->
  <header id="header">
    <div class="d-flex flex-column">

      <div class="profile">
        <img :src="require('@/assets/img/profile-img.jpg')" alt="profile" class="img-fluid rounded-circle">
        <h1 class="text-light text-center">{{ $t('hero.name') }}</h1>
        <h1 class="text-light text-center">{{ $t('hero.surname') }}</h1>
        <div class="social-links mt-3 text-center">
          <a href="https://www.linkedin.com/in/nikos-manoukidis-7b4b93141/" target="_blank" class="twitter"><i class="bx bxl-linkedin"></i></a>
          <a href="https://www.facebook.com/nmanoukidis/" target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
          <a href="https://www.instagram.com/nmanoukidis/" target="_blank" class="instagram"><i class="bx bxl-instagram"></i></a>
          <a href="skype:live:nmanoukidis_1?chat" target="_blank" class="google-plus"><i class="bx bxl-skype"></i></a>
        </div>
      </div>

      <nav id="navbar" class="nav-menu navbar">
        <ul v-if="this.$route.name === 'Home' || this.$route.name === 'NotFound'">
          <li><a :href="this.$route.name === 'NotFound' ? '/' : '#hero'" class="nav-link scrollto active"><i class="bx bx-home"></i> <span>{{ $t('hero.title') }}</span></a></li>
          <li><a :href="this.$route.name === 'NotFound' ? '/' : '#about'" class="nav-link scrollto"><i class="bx bx-user"></i> <span>{{ $t('about.title') }}</span></a></li>
          <li><a :href="this.$route.name === 'NotFound' ? '/' : '#resume'" class="nav-link scrollto"><i class="bx bx-file-blank"></i> <span>{{ $t('resume.title') }}</span></a></li>
          <li><a :href="this.$route.name === 'NotFound' ? '/' : '#portfolio'" class="nav-link scrollto"><i class="bx bx-book-content"></i> <span>{{ $t('portfolio.title') }}</span></a></li>
          <li><a id="contact-link" :href="this.$route.name === 'NotFound' ? '/' : '#contact'" class="nav-link scrollto"><i class="bx bx-envelope"></i> <span>{{ $t('contact.title') }}</span></a></li>
          <li v-if="this.$i18n.locale === 'el'" @click="switchLocale(this.$root.locales[1].short)"><a href="javascript:void(0)" class="nav-link scrollto"><span><img class="me-2" :src="this.$root.locales[1].flag" alt="gr" height="30">{{ this.$root.locales[1].title }}</span></a></li>
          <li v-else-if="this.$i18n.locale === 'en'" @click="switchLocale(this.$root.locales[0].short)"><a href="javascript:void(0)" class="nav-link scrollto"><span><img class="me-2" :src="this.$root.locales[0].flag" alt="usa" height="30">{{ this.$root.locales[0].title }}</span></a></li>
        </ul>
        <ul v-else>
          <li><router-link :to="{name: 'Home'}" class="nav-link scrollto"><i class="bx bx-home"></i> <span>{{ $t('hero.title') }}</span></router-link></li>

          <li><router-link :to="{name: 'Octopus'}" :class="'nav-link scrollto' + (this.$route.name === 'Octopus' ? ' active' : '')"><img :src="require('@/assets/img/portfolio/octopus/favicon.png')" :alt="''" :height="'30'" class="me-2"> <span>{{ $t('portfolio.Octopus.title') }}</span></router-link></li>
          <li><router-link :to="{name: 'Mentors'}" :class="'nav-link scrollto' + (this.$route.name === 'Mentors' ? ' active' : '')"><img :src="require('@/assets/img/portfolio/mentors/favicon.jpeg')" :alt="''" :height="'30'" class="me-2"> <span>{{ $t('portfolio.Mentors.title') }}</span></router-link></li>
          <li><router-link :to="{name: 'Frontizo'}" :class="'nav-link scrollto' + (this.$route.name === 'Frontizo' ? ' active' : '')"><img :src="require('@/assets/img/portfolio/animal-welfare/favicon.png')" :alt="''" :height="'30'" class="me-2"> <span>{{ $t('portfolio.Frontizo.title') }}</span></router-link></li>

          <li v-if="this.$i18n.locale === 'el'" @click="switchLocale(this.$root.locales[1].short)"><a href="javascript:void(0)" class="nav-link scrollto"><span><img class="me-2" :src="this.$root.locales[1].flag" alt="gr" height="30">{{ this.$root.locales[1].title }}</span></a></li>
          <li v-else-if="this.$i18n.locale === 'en'" @click="switchLocale(this.$root.locales[0].short)"><a href="javascript:void(0)" class="nav-link scrollto"><span><img class="me-2" :src="this.$root.locales[0].flag" alt="usa" height="30">{{ this.$root.locales[0].title }}</span></a></li>
        </ul>
      </nav><!-- .nav-menu -->
    </div>
  </header><!-- End Header -->
</template>

<script>
export default {
  name: "Sidebar",
  mounted() {
    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      } else {
        return document.querySelector(el)
      }
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all)
      if (selectEl) {
        if (all) {
          selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
          selectEl.addEventListener(type, listener)
        }
      }
    }

    /**
     * Mobile nav toggle
     */
    // eslint-disable-next-line no-unused-vars
    on('click', '.mobile-nav-toggle', function() {
      select('body').classList.toggle('mobile-nav-active')
      this.classList.toggle('bi-list')
      this.classList.toggle('bi-x')
    })
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    }
  }
}
</script>

<style scoped>

</style>