<template>
  <!-- ======= Portfolio Section ======= -->
  <section id="portfolio" class="portfolio section-bg">
    <div class="container">

      <div class="section-title">
        <h2>{{ $t('portfolio.title') }}</h2>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active">{{ $t('portfolio.categories.0') }}</li>
            <li data-filter=".filter-php">{{ $t('portfolio.categories.1') }}</li>
            <li data-filter=".filter-laravel">{{ $t('portfolio.categories.2') }}</li>
          </ul>
        </div>
      </div>

      <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="100">

        <div class="col-lg-4 col-md-6 portfolio-item filter-laravel">
          <div class="portfolio-wrap">
            <img :src="require('@/assets/img/portfolio/octopus/octopus.png')" class="img-fluid" alt="">
            <div class="portfolio-links">
              <router-link :to="{name: 'Octopus'}" :title="$t('portfolio.details')"><i class="bx bx-link"></i></router-link>
<!--              <a :href="'octopus'" :title="$t('portfolio.details')"><i class="bx bx-link"></i></a>-->
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-php">
          <div class="portfolio-wrap">
            <img :src="require('@/assets/img/portfolio/mentors/mentors.png')" class="img-fluid" alt="">
            <div class="portfolio-links">
              <router-link :to="{name: 'Mentors'}" :title="$t('portfolio.details')"><i class="bx bx-link"></i></router-link>
<!--              <a :href="'mentors'" :title="$t('portfolio.details')"><i class="bx bx-link"></i></a>-->
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-php">
          <div class="portfolio-wrap">
            <img :src="require('@/assets/img/portfolio/animal-welfare/animal-welfare.png')" class="img-fluid" alt="">
            <div class="portfolio-links">
              <router-link :to="{name: 'Frontizo'}" :title="$t('portfolio.details')"><i class="bx bx-link"></i></router-link>
<!--              <a :href="'frontizo'" :title="$t('portfolio.details')"><i class="bx bx-link"></i></a>-->
            </div>
          </div>
        </div>
      </div>

    </div>
  </section><!-- End Portfolio Section -->
</template>

<script>
export default {
  name: "Portfolio"
}
</script>

<style scoped>

</style>