<template>
  <!-- ======= Breadcrumbs ======= -->
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">

      <div class="d-flex justify-content-between align-items-center">
        <h2>{{ title }}</h2>
        <ol>
          <li><a :href="'/'">{{ $t('hero.title') }}</a></li>
          <li>{{ title }}</li>
        </ol>
      </div>

    </div>
  </section><!-- End Breadcrumbs -->
</template>

<script>
export default {
  name: "Breadcrumb",
  props: ['title']
}
</script>

<style scoped>

</style>