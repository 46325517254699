export default {
  "hero": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikolaos"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manoukidis"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm"])},
    "description_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Developer, Web Designer"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name is Nick Manoukidis. My main objective is improving my skill-set in website and web application development."])},
    "card": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Information"])},
      "birthday": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April 6 1998"])}
      ],
      "city": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thessaloniki, Greece"])}
      ],
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "phone": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+30 699 575 2061"])}
      ],
      "degree": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor"])}
      ],
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Born and raised in Thessaloniki, Greece in 1998, I have been an aspiring programmer since a very early age, i.e. since the first grades of Junior High School. My father, who is also a programmer, has been my most valuable mentor in my journey through the world of Computer Science. I graduated from the 1st Vocational Senior High School of Kalamaria, Thessaloniki, having successfully completed the Computer Science track. In 2016 I was granted a full scholarship in CITY COLLEGE (The International Faculty of the University of Sheffield) where I was included twice in the “Students’ Head List” during the course of my studies. In 2020 I successfully graduated as a Bachelor Degree holder in Computer Science and as of September 2022, I am a contractor for PROCON (Professional Consulting) as a Web Developer."])}
    }
  },
  "skills": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])}
  },
  "resume": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume"])},
    "education": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "items": {
        "bachelor": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor of Science in Computer Science"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2016 - 2020"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CITY COLLEGE - The International Faculty of the University of Sheffield"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-year Scholarship - Graduated with Class II - Division II"])}
        ],
        "high-school": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High School Diploma"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2016"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st Vocational Senior High School of Kalamaria, Thessaloniki"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Computer Science Track - Graduated with 16.5/20"])}
        ]
      }
    },
    "languages": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreign Languages"])},
      "items": {
        "english": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate of English Language"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December, 2017"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proficiency, C2"])}
        ],
        "german": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate of German Language"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November, 2015"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goethe Zertifikat, B2"])}
        ]
      }
    },
    "professional": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Experience"])},
      "items": {
        "procon": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Developer"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09/2022 - Today"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROCON (Professional Consulting)"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member of the Web Developers' team"])}
        ],
        "custom-lab": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP Developer"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03/2021 - 08/2021"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customlab Development & Web services"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member of the Web Developers' team"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional websites building"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Support"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Support"])}
        ],
        "gr-net": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Developer"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09/2020 - 03/2021"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GR-NET L.P."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member of the Web Developers' team"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Shops & Professional Websites Building & Maintenance"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Technical Support"])}
        ]
      }
    }
  },
  "portfolio": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "categories": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laravel"])}
    ],
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Details"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "Octopus": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octopus"])},
      "overview": {
        "category": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Application"])}
        ],
        "technologies": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologies Used"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laravel, PHP 7.4, HTML 5, Bootstrap 4, CSS 3, JavaScript, Ajax, MySQL"])}
        ],
        "date": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project date"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December, 2020"])}
        ],
        "collaborators": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborators"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Athanasakis Theodosis"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://theathral.github.io"])}
        ],
        "url": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project URL"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octopus"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://octopus.nmanoukidis.eu/"])}
        ]
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Octopus\" application is an incident management system geared towards small companies. Every user is part of one or more groups in order to acquire specific capabilities inside the program. Furthermore, users can fulfill, depending on their privileges, the actions below: Incident and Request Management (Submission, Case Monitoring, Completion), Statistics Monitoring Chatting between Users, Group and Privileges Creation, Log Files Surveillance The application is offered as SaaS and created using the \"Laravel\" programming language for advanced security."])}
    },
    "Mentors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer Mentoring System"])},
      "overview": {
        "category": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Application"])}
        ],
        "technologies": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologies Used"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP 7.3, HTML 5, Bootstrap 4, CSS 3, JavaScript, Ajax, MySQL"])}
        ],
        "date": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project date"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June, 2020"])}
        ]
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The “Peer Mentoring System” is an online platform through which students are offered the chance of offering and receiving help from each other through the use of technological means. In this way, students may choose to share their knowledge with weaker colleagues, or receive any kind of academic support from stronger ones. This system actually enhances the previously mentioned peer collaboration, even if the two parts do not know each other in person"])}
    },
    "Frontizo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontizo"])},
      "overview": {
        "category": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Application"])}
        ],
        "technologies": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologies Used"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP 7.1, HTML 5, Bootstrap 4, CSS 3, JavaScript, Ajax, MySQL"])}
        ],
        "date": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project date"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November, 2019"])}
        ]
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Frontizo\" comes from the greek word \"φροντίζω\", which means \"care\" / \"take care of...\". The idea came from an animal welfare union of the municipality of Thessaloniki and was implemented as a group project which worked like an internship in the college. The members of the union are offered the opportunity to record information about the animals that they collect, vaccinate, etc. They can also keep track of their warehouse, the donations received and the payments made to their suppliers."])}
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "location": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalamaria, Thessaloniki, Greece"])}
    ],
    "call": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+30 699 575 2061"])}
    ],
    "resume": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Resume"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manoukidis-cv-en.pdf"])}
    ],
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
      "sending": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message has been sent. Thank you."])}
      ]
    }
  },
  "errors": {
    "404": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAGE NOT FOUND"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOMEPAGE"])}
    ]
  },
  "responses": {
    "errors": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please insert a valid name"])},
      "recaptcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recaptcha validation error"])}
    }
  }
}